import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class TabsService {
    public libraryTabWasSelected: BehaviorSubject<any> = new BehaviorSubject(null);
    public trainingWasPassed: BehaviorSubject<any> = new BehaviorSubject(null);
    constructor() { }
}
