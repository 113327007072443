import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
    imports:      [IonicModule, CommonModule, FormsModule, ReactiveFormsModule],
    declarations: [LoaderComponent],
    exports:      [LoaderComponent],
})
export class SharedModule {}
