import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Question } from '../practice.model';

@Component({
    selector:    'app-details',
    templateUrl: './details.component.html',
    styleUrls:   ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
    @Input() question: Question;
    constructor(
        private modalCtrl: ModalController,
    ) { }

    ngOnInit() {
    }

    dismissModal() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalCtrl.dismiss({
            dismissed: true,
        });
    }
}
