import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';

import { ShellComponent } from './shell.component';

@NgModule({
    imports:         [CommonModule, IonicModule, RouterModule],
    entryComponents: [ShellComponent],
    declarations:    [ShellComponent],
})
export class ShellModule {}
