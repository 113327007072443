import { Component, OnInit, OnDestroy } from '@angular/core';

import { environment } from '@env/environment';
import { finalize, filter, tap, debounceTime, map, switchMap } from 'rxjs/operators';
import { StoreService, AppState, IResult } from '@app/core/store/store.service';
import { ld, untilDestroyed } from '@app/core';
import { Rule } from '@app/core/store/models/rule.model';
import { Exercise } from '@app/core/store/models/exercise.model';
import { DomSanitizer } from '@angular/platform-browser';
import { Route, Router, ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TrainingService } from './training.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { TabsService } from '@app/shell/tabs.service';

@Component({
    selector: 'app-training',
    templateUrl: './training.component.html',
    styleUrls: ['./training.component.scss'],
})
export class TrainingComponent implements OnInit, OnDestroy {
    version: string | null = environment.version;
    isLoading = true;

    items: any;
    public search$: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(
        private storeService: StoreService,
        private domSanitizer: DomSanitizer,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private tabsService: TabsService,
    ) { }

    ngOnInit() {
        this.isLoading = true;

        this.tabsService.trainingWasPassed
            .pipe(
                untilDestroyed(this),
            )
            .subscribe((res) => {
                this.search$.next(res);
            });

        combineLatest([this.storeService.store$, this.search$]).pipe(
            filter(([state, search]: [AppState, IResult[]]) => {
                return state.ready;
            }),
            map(([state, search]: [AppState, IResult[]]) => {
                const resultIdMap = new Map();
                ld.forEach((state.lastTrainingResults || []).concat(search || []), (item: IResult) => {
                    resultIdMap.set(item.id, item.result);
                });
                const preparedItems = state.exercises.map((item: Exercise) => {
                    item.fullImgUrl = this.domSanitizer.bypassSecurityTrustStyle(`url(${environment.serverUrl}/${item.image})`);
                    item.lastResult = resultIdMap.get(item.id) || 0;
                    return item;
                });
                return preparedItems;
            }),
            untilDestroyed(this),
        )
        .subscribe((exercises: Exercise[]) => {
            this.isLoading = false;
            this.items = exercises;
        });
    }

    ngOnDestroy() { }

    navigate(item: any) {
        this.router.navigate(['/tabs/training/one', item.id]);
    }
}
