/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Observable, from, of, BehaviorSubject, forkJoin } from 'rxjs';
import { switchMap, map, tap, first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { Practice } from '@app/training/one-practice/practice.model';
import { User } from './models/user.model';
import { Category } from './models/category.model';
import { Rule } from './models/rule.model';
import { ld } from '../index';
import { Exercise } from './models/exercise.model';
import { Capacitor } from '@capacitor/core';

const DEBUG_MODE = false;

export interface AppState {
    user: User | null;
    categories: Category[];
    exercises: Exercise[];
    rules: Rule[];
    ready: boolean;
    lastExercise?: Practice;
    lastTrainingResults?: IResult[];
    favorites?: number[];
}

export interface IResult {
    id: number;
    result: number;
}

@Injectable({
    providedIn: 'root',
})
export class StoreService {
    public lastResultsKey = `lastExercisesResults`;
    public state: AppState = {
        user:       null,
        categories: [],
        exercises:  [],
        rules:      [],
        lastTrainingResults:    [],
        ready:      false,
    };

    public store$: BehaviorSubject<AppState> = new BehaviorSubject(this.state);

    constructor(
        private storage: Storage,
        private httpClient: HttpClient,
        private domSanitizer: DomSanitizer,
    ) { }

    public initStore(): Observable<AppState> {
        if (this.state.ready) {
            return of(this.state);
        }

        return forkJoin([
            this.getCategories(),
            this.getRules(),
            this.getExercises(),
            this.getDataFromStorage('lastExercise'),
            this.getDataFromStorage('favorites'),
            this.getDataFromStorage(this.lastResultsKey),
        ]).pipe(
            tap((results: any) => {
                const [categories, rules, exercises, lastExercise, favorites, lastTrainingResults] = results;
                this.state.categories   = categories;
                this.state.rules        = rules;
                this.state.exercises    = exercises;
                this.state.lastExercise = lastExercise;
                this.state.favorites    = favorites;
                this.state.lastTrainingResults    = lastTrainingResults;

                this.state.ready = true;
                this.store$.next(this.state);
            }),
        );
    }

    // public getUser():Observable<User> {

    // }

    public getRules(minId = 0): Observable<any> {
        let allRules: Rule[];
        return this.getDataFromStorage('rules').pipe(
            switchMap((dataInStorage: Rule[]) => {
                if (!DEBUG_MODE && dataInStorage) {
                    return of(dataInStorage);
                }
                return this.httpClient.get(`?route=examples&minId=${minId}`);
            }),
            switchMap((rules: any) => {
                allRules = rules;
                return this.setDataToStorage('rules', rules);
            }),
            switchMap(() => this.getCategories()),
            map((categories: Category[]) => {
                const categoryKeys = {};
                categories.forEach((category: Category) => {
                    categoryKeys[category.id] = category.title;
                });
                const preparedItems = allRules.map((item: Rule) => {
                    item.fullImgUrl   = `${environment.serverUrl}/${item.image}`;
                    item.shortSummary = ld.truncate(item.content, { length: 100, separator: ' ' });
                    item.categoryName = categoryKeys[item.category];
                    return item;
                });
                const sortedById = ld.orderBy(preparedItems, 'id', ['desc', 'asc']);
                return sortedById;
            }),
        );
    }

    public getCategories(): Observable<any> {
        return this.getDataFromStorage('categories').pipe(
            switchMap((dataInStorage: Category[]) => {
                if (!DEBUG_MODE && dataInStorage) {
                    return of(dataInStorage);
                }
                return this.httpClient.get('?route=categories');
            }),
            map((categories: any) => ld.map(categories, (item: Category) => {
                    item.selected = item.selected === undefined ? true : item.selected;
                    return item;
                })),
            switchMap((categories: any) => this.setDataToStorage('categories', categories)),
        );
    }

    public getExercises(): Observable<any> {
        return this.getDataFromStorage('exercises').pipe(
            switchMap((dataInStorage: Exercise[]) => {
                if (!DEBUG_MODE && dataInStorage) {
                    return of(dataInStorage);
                }
                return this.httpClient.get('?route=tests');
            }),
            map((exercises: Exercise[]) => {
                const preparedItems = exercises.map((item: Exercise) => item);
                const sortedById    = ld.orderBy(preparedItems, 'id', ['asc']); // 'desc'
                return sortedById;
            }),
            switchMap((exercises: Exercise[]) => this.setDataToStorage('exercises', exercises)),
        );
    }

    public getFavoritesRules() {
        return this.getDataFromStorage('favorites');
    }

    public setStoreData(key: string, data: any): Observable<any> {
        return this.setDataToStorage(key, data).pipe(
            tap((res) => {
                this.state[key] = data;
                this.store$.next(ld.cloneDeep(this.state));
            }),
        );
    }

    public getDataFromStorage(key: string): Observable<any> {
        return from(
            this.storage.get(key).then((value: any) => value),
        );
    }

    public setDataToStorage(key: string, value: any): Observable<any> {
        // console.log('value: ', value);
        return from(
            this.storage.set(key, value),
        ).pipe(
            switchMap(() => {
                return this.getDataFromStorage(key);
            })
        );
    }
}
