import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ActionSheetController, AlertController, Platform } from '@ionic/angular';
import { TextFieldTypes } from '@ionic/core';

import { AuthenticationService, CredentialsService } from '@app/core';

@Component({
    selector:    'app-settings',
    templateUrl: './settings.component.html',
    styleUrls:   ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private platform: Platform,
        private alertController: AlertController,
        private actionSheetController: ActionSheetController,
        private authenticationService: AuthenticationService,
        private credentialsService: CredentialsService,
    ) {}

    ngOnInit() {}

    get isWeb(): boolean {
        return !this.platform.is('cordova');
    }

    get username(): string | null {
        const { credentials } = this.credentialsService;
        return credentials ? credentials.username : null;
    }

    logout() {
        this.authenticationService.logout().subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
    }
}
