import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { TrainingComponent } from './training.component';
import { OnePracticeComponent } from './one-practice/one-practice.component';
import { CanDeactivateOnePracticeGuard } from './one-practice/one-practice-candeactivate.service';

// canDeactivate: [CanDeactivateOnePracticeGuard],
const routes: Routes = [
    Shell.childRoutes([
        { path: '', redirectTo: '/training', pathMatch: 'full' },
        { path: 'training', component: TrainingComponent, data: { title: extract('Вправи') } },
        { path: 'training/one/:id', component: OnePracticeComponent, data: { title: extract('Вправа') } },
    ]),
];
@NgModule({
    imports:   [RouterModule.forChild(routes)],
    exports:   [RouterModule],
    providers: [CanDeactivateOnePracticeGuard],
})
export class TrainingRoutingModule {}
