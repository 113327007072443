import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Credentials, CredentialsService } from './credentials.service';

export interface LoginContext {
    email: string;
    password: string;
    remember?: boolean;
}

type SocilLogin = 'Facebook' | 'Google';

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    constructor(
        private credentialsService: CredentialsService,
        private httpClient: HttpClient,
    ) {}

    /**
     * Authenticates the user.
     * @param context The login parameters.
     * @return The user credentials.
     */
    login(context: LoginContext): Observable<any> {
        // Replace by proper authentication call
        // const data = {
        //     username: context.username,
        //     token:    '123456',
        // };
        // this.credentialsService.setCredentials(data, context.remember);
        // return of(data);
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        return this.httpClient.post('?route=login', {
            password: context.password,
            username: context.email,
        }, { headers });
    }

    loginSocial(type: SocilLogin, accessToken: string, idUser: number) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
        return this.httpClient.post('?route=login', {
            provider: type,
            idToken:  accessToken,
            accessToken,
            id_user:  idUser,
        }, { headers });
    }

    getFormData(object: any) {
        const formData = new FormData();
        Object.keys(object).forEach(key => formData.append(key, object[key]));
        return formData;
    }

    /**
     * Logs out the user and clear credentials.
     * @return True if the user was logged out successfully.
     */
    logout(): Observable<boolean> {
        // Customize credentials invalidation here
        this.credentialsService.setCredentials();
        return of(true);
    }
}
