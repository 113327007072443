import { Injectable } from '@angular/core';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Http, HttpDownloadFileResult } from '@capacitor-community/http';
import { Storage } from '@ionic/storage-angular';
// import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions';

// import { FileTransfer, FileUploadOptions, FileTransferObject } from '@awesome-cordova-plugins/file-transfer/ngx';
// import { File } from '@awesome-cordova-plugins/file/ngx';
import { Rule } from './store/models/rule.model';
import { environment } from '../../environments/environment';
import { ld, Logger } from './index';
import { StoreService } from './store/store.service';
import { filter, first } from 'rxjs/operators';

const log = new Logger('QueueService');

@Injectable({
    providedIn: 'root',
})
export class QueueService {
    constructor(
        private storeService: StoreService,
        private storage: Storage,
    ) { }

    // const fileTransfer: FileTransferObject = this.transfer.create();

    // constructor(private transfer: FileTransfer, private file: File) { }

    public async initQueue(rules: Rule[]) {
        const onlyWithoutImage = ld.filter(rules, (rule: Rule) => !rule.localImage);
        log.debug('onlyWithoutImage: ', onlyWithoutImage.length);

        if (onlyWithoutImage.length === 0) {
            return;
        }

        await Promise.all(ld.map(onlyWithoutImage.slice(0, 10), async (rule) => {
            const savedImg = await this.download(rule);
            rule.localImage = savedImg;
        }));
        this.storage.set('rules', rules);
        await new Promise((resolve, rejects) => {
            setTimeout(() => {
                // log.debug('tik');
                resolve(true);
            }, 500);
        });
        this.initQueue(rules);
        // let counterForSaving = 0;
        // for (const rule of onlyWithoutImage) {
        //     const savedImg = await this.download(rule);
        //     rule.localImage = savedImg;
        //     // log.debug('rule.localImage: ', rule.localImage);
        //     await new Promise((resolve, rejects) => {
        //         setTimeout(() => {
        //             log.debug('tik');
        //             resolve(true);
        //         }, 0);
        //     });

        //     // save to store only every 10 images
        //     counterForSaving ++;
        //     if (counterForSaving > 100) {
        //         counterForSaving = 0;
        //         // this.storeService.setStoreData('rules', rules).pipe(first()).subscribe();
        //         this.storage.set('rules', rules);
        //     }
        // }
    }

    // private write

    async download(rule: Rule) {
        const imageFileName = `${this.makeRandomPrefix(5)}-saved-${rule.id}.png`;
        const options = {
            url: rule.fullImgUrl,
            filePath: imageFileName,
            fileDirectory: Directory.Library,
            method: 'GET',
        };
        try {
            // Writes to local filesystem
            const response: HttpDownloadFileResult = await Http.downloadFile(options);
            return response.path || '';
        } catch (error) {
            log.debug('error: ', error);
            return '';
        }
    }

    private makeRandomPrefix(length) {
        let result             = '';
        const characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
}
