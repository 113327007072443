import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Category } from '@app/core/store/models/category.model';
import { StoreService, AppState } from '@app/core/store/store.service';
import { untilDestroyed, ld } from '@app/core';
import { filter, first, map } from 'rxjs/operators';

@Component({
    selector:    'app-library-filter',
    templateUrl: './library-filter.component.html',
    styleUrls:   ['./library-filter.component.scss'],
})
export class LibraryFilterComponent implements OnInit, OnDestroy {
    @Input() currentSelected: number;
    public categories$: Observable<Category[]>;
    public categories: Category[];
    masterCheck: boolean;
    isIndeterminate: boolean;
    totalSelected: number;

    noNeedUpdateStore: boolean;

    constructor(private storeService: StoreService) {}

    ngOnInit() {
        this.storeService.store$
            .pipe(
                filter((state: AppState) => {
                    return state.ready;
                }),
                map((state: AppState) => {
                    return state.categories;
                }),
                first(),
                untilDestroyed(this),
            )
            .subscribe((res) => {
                this.noNeedUpdateStore = true;
                this.categories        = res;
                this.checkEvent();
            });
    }

    ngOnDestroy() {}

    checkMaster() {
        setTimeout(() => {
            this.categories.forEach((obj) => {
                obj.selected = this.masterCheck;
            });
            this.checkEvent();
        });
    }

    checkEvent(item?: any) {
        const totalItems = this.categories.length;
        let checked      = 0;
        this.categories.map((obj) => {
            if (obj.selected) {
                checked += 1;
            }
            return obj;
        });
        if (checked > 0 && checked < totalItems) {
            // If even one item is checked but not all
            this.isIndeterminate = true;
            this.masterCheck     = false;
        } else if (checked === totalItems) {
            // If all are checked
            this.masterCheck     = true;
            this.isIndeterminate = false;
        } else {
            // If none is checked
            this.isIndeterminate = false;
            this.masterCheck     = false;
        }

        this.totalSelected = checked;

        if (!this.noNeedUpdateStore) {
            setTimeout(() => {
                this.storeService.setStoreData('categories', this.categories).subscribe();
            }, 100);
        }
        this.noNeedUpdateStore = false;
    }
}
