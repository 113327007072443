import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { IonicStorageModule } from '@ionic/storage-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { FavoritesModule } from './favorites/favorites.module';
// import { LoginModule } from './login/login.module';
import { SettingsModule } from './settings/settings.module';
import { SharedModule } from './shared';
import { ShellModule } from './shell/shell.module';
import { TrainingModule } from './training/training.module';

import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';
import { Drivers } from '@ionic/storage';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        CoreModule,
        SharedModule,
        ShellModule,
        FavoritesModule,
        TrainingModule,
        SettingsModule,
        // LoginModule,
        HttpClientModule,
        IonicModule.forRoot(),
        IonicStorageModule.forRoot({
            driverOrder: [CordovaSQLiteDriver._driver, Drivers.IndexedDB]
        }),
        AppRoutingModule
    ],
    providers: [
        // { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
        // FileTransfer,
        Keyboard,
        StatusBar,
        SplashScreen,
        // File,
        SocialSharing
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
