/* eslint-disable @typescript-eslint/member-ordering */
import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService, AppState } from '@app/core/store/store.service';
import { ld } from '@app/core';
import { map, switchMap, first, tap, filter } from 'rxjs/operators';
import { Exercise } from '@app/core/store/models/exercise.model';
import { ModalController, IonRouterOutlet, AlertController } from '@ionic/angular';
// import { trigger, transition, animate, style } from '@angular/animations';
import { of, from } from 'rxjs';
import { TrainingService } from '../training.service';
import { Practice, Question } from './practice.model';
import { DetailsComponent } from './details/details.component';
import { TabsService } from '@app/shell/tabs.service';

@Component({
    selector:    'app-one-practice',
    templateUrl: './one-practice.component.html',
    styleUrls:   ['./one-practice.component.scss'],
    animations:  [
        // trigger('slideInOut', [
        //     transition(':enter', [
        //         style({ transform: 'translateX(-100%)' }),
        //         animate('200ms ease-in-out', style({ transform: 'translateX(0%)' })),
        //     ]),
        //     transition(':leave', [
        //         animate('200ms ease-in-out', style({ transform: 'translateX(-100%)' })),
        //     ]),
        // ]),
        // trigger('fadeIn', [
        //     transition('void => *', [
        //         style({ opacity: 0 }),
        //         animate(250, style({ opacity: 1 })),
        //     ]),
        // ]),
    ],
})
export class OnePracticeComponent implements OnInit {
    @Input() practice: any;
    public tasks: Question[];
    public title: string;
    public currentQuetion = {};
    public showQuestion = false;

    public currentQuestionId: number;
    public totalCorrectAnswers: number;
    public isNeedShowResult: boolean;
    totalMessage: string;

    private currentId: string;
    private selectedTemp: null|number = null;

    constructor(
        private activatedRoute: ActivatedRoute,
        private alertController: AlertController,
        private tabsService: TabsService,
        private storeService: StoreService,
        private trainingService: TrainingService,
        private modalController: ModalController,
        private routerOutlet: IonRouterOutlet,
    ) { }

    ngOnInit() {
        // this.storeService.setDataToStorage(this.storeService.lastResultsKey, []).subscribe();

        this.currentId               = this.activatedRoute.snapshot.paramMap.get('id');
        let savedPractice: Practice;
        let selectedNow: Exercise;
        let lastRun: Practice;
        let isOldPracticeIsAvailable = false;
        let isNeedToRestorePrevius   = false;

        const savedPracticeKey = `lastExercise_${this.currentId}`;

        this.storeService.store$
            .pipe(
                filter(result => !!result.ready),
                map((result: AppState) => ({ exercises: result.exercises, last: result[savedPracticeKey] || null })),
                first(),
                switchMap((results: {exercises: Exercise[]; last: Practice}) => {
                    selectedNow = ld.find(results.exercises, { id: +this.currentId }) as Exercise;
                    lastRun     = results.last;
                    if (this.isAvailableOldPractice(results, selectedNow)) {
                        isOldPracticeIsAvailable = true;
                        return from(this.presentAlertConfirmContinuePreviusePractice());
                    }
                    return of(true);
                }),
                map((result: boolean) => {
                    if (result) {
                        return this.trainingService.preparePractice(selectedNow);
                    }
                    isNeedToRestorePrevius = isOldPracticeIsAvailable;
                    return lastRun;
                }),
                switchMap((practice: Practice) => {
                    savedPractice = practice;
                    return this.storeService.setStoreData(savedPracticeKey, practice).pipe(first());
                }),
            )
            .subscribe(() => {
                this.title  = savedPractice.title;
                this.tasks  = savedPractice.questions;
                // this.tasks  = this.tasks.slice(0, 2);
                let firstId = 0;
                if (isNeedToRestorePrevius) {
                    ld.forEach(this.tasks, (question: Question) => {
                        if (question.userSelected === null) {
                            firstId = question.id - 1;
                            return false;
                        }
                    });
                }
                this.showCurrentQuestion(firstId);
            });
    }

    ngOnDestroy(): void {
    }

    public retry() {
        ld.forEach(this.tasks, (question: Question) => {
            question.userSelected = null;
        });
        this.isNeedShowResult = false;
        this.showCurrentQuestion(0);
    }

    public showCurrentQuestion(id: number) {
        this.showQuestion = false;
        const current     = this.tasks[id];
        if (!current) {
            return;
        }
        this.currentQuetion    = {
            question: current.title,
            answers:  current.variants,
        };
        this.currentQuestionId = id;
        setTimeout(() => {
            this.showQuestion = true;
        }, 10);
    }

    public showResult() {
        this.isNeedShowResult    = true;
        this.totalCorrectAnswers = ld.filter(this.tasks, (question: Question) => question.correct === question.userSelected).length;
        this.currentQuestionId   = 1000;
        if (this.totalCorrectAnswers >= 8) {
            this.totalMessage = 'Чудово';
        } else if (this.totalCorrectAnswers > 3) {
            this.totalMessage = 'Можна і краще';
        } else {
            this.totalMessage = 'Треба тренуватись';
        }

        this.storeService.getDataFromStorage(this.storeService.lastResultsKey).pipe(
            first(),
            switchMap((lastResults: {id: number; result: number}[]) => {
                const prepared = lastResults || [];
                const found = ld.find(prepared, {id: +this.currentId});
                if (found) {
                    found.result = this.totalCorrectAnswers;
                } else {
                    prepared.push({id: +this.currentId, result :this.totalCorrectAnswers});
                }
                return this.storeService.setDataToStorage(this.storeService.lastResultsKey, ld.uniqBy(prepared, 'id'));
            })
        ).subscribe((res) => {
            this.tabsService.trainingWasPassed.next(res);
        });
    }

    public next() {
        this.showCurrentQuestion(this.currentQuestionId + 1);
    }

    public verify() {
        const current        = this.getCurrentQuestion;
        current.userSelected = this.selectedTemp;
        if (!this.isNeedToShowResultButton) {
            this.selectedTemp = null;
        }
    }

    public selectedVariant(selected: any) {
        this.selectedTemp = selected.detail.value;
    }

    async showRule() {
        const modal = await this.modalController.create({
            component:         DetailsComponent,
            swipeToClose:      true,
            presentingElement: this.routerOutlet.nativeEl,
            componentProps:    {
                question: this.getCurrentQuestion,
            },
        });
        // eslint-disable-next-line no-return-await
        return await modal.present();
    }

    async presentAlertConfirmContinuePreviusePractice(): Promise<boolean> {
        // await alert.present();
        return new Promise((resolve: any, reject: any) => {
            this.alertController.create({
                header:  'Хочете продовжити, чи почати вправу зпочатку?',
                buttons: [
                    {
                        text:     'Продовжити',
                        role:     'cancel',
                        cssClass: 'secondary',
                        handler:  (blah) => {
                            resolve(false);
                        },
                    }, {
                        text:    'З початку',
                        handler: () => {
                            resolve(true);
                        },
                    },
                ],
            }).then(alert => alert.present());
        });
    }

    private isAvailableOldPractice(results: { exercises: Exercise[]; last: Practice }, selectedNow: Exercise) {
        if (!results.last) {
            return;
        }
        const { questions } = results.last;
        return results.last
            && selectedNow.id === results.last.id
            && questions[0].userSelected !== null
            && questions[questions.length - 1].userSelected === null;
    }

    get getCurrentQuestion(): Question {
        return this.tasks[this.currentQuestionId];
    }

    get isCurrentQuestionWasSelected() {
        return this.selectedTemp !== null && !this.isNeedToShowResultButton;
    }

    get isNeedToShowNextButton() {
        return this.tasks.length > this.currentQuestionId && this.getCurrentQuestion.userSelected !== null;
    }

    get isNeedToShowResultButton() {
        return (this.tasks.length - 1) === this.currentQuestionId && this.getCurrentQuestion.userSelected !== null;
    }

    get isNeedToShowRuleButton() {
        return this.getCurrentQuestion.userSelected !== null;
    }

    get isCorrectAnswer() {
        const selected = this.getCurrentQuestion.userSelected;
        return selected !== null && this.getCurrentQuestion.correct === selected;
    }

    get isWrongAnswer() {
        const selected = this.getCurrentQuestion.userSelected;
        return selected !== null && this.getCurrentQuestion.correct !== selected;
    }

    get currentAnswered() {
        return this.getCurrentQuestion.userSelected;
    }
}
