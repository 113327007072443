import { Component, OnInit } from '@angular/core';
import { ld, untilDestroyed } from '@app/core';
import { Rule } from '@app/core/store/models/rule.model';
import { AppState, StoreService } from '@app/core/store/store.service';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, finalize, map, tap } from 'rxjs/operators';
// import { trigger, transition, animate, style } from '@angular/animations';

import { QuoteService } from './quote.service';
import { LibraryComponent } from '@app/library/library.component';
import { LibraryService } from '@app/library/library.service';
import { SocialShareService } from '@app/shared/social-share.service';
import { TabsService } from '@app/shell/tabs.service';
import { PopoverController, ModalController, IonRouterOutlet, Platform } from '@ionic/angular';

@Component({
    selector:    'app-favorites',
    templateUrl: './favorites.component.html',
    styleUrls:   ['./favorites.component.scss'],
    // animations:  [
    //     trigger('slideInOut', [
    //         transition(':enter', [
    //             style({ transform: 'translateX(-100%)' }),
    //             animate('200ms ease-in-out', style({ transform: 'translateX(0%)' })),
    //         ]),
    //         transition(':leave', [
    //             animate('200ms ease-in-out', style({ transform: 'translateX(-100%)' })),
    //         ]),
    //     ]),
    //     trigger('fadeIn', [
    //         transition('void => *', [
    //             style({ opacity: 0 }),
    //             animate(250, style({ opacity: 1 })),
    //         ]),
    //     ]),
    // ],
})
export class FavoritesComponent extends LibraryComponent implements OnInit {
    quote: string | undefined;
    isLoading = false;
    search$: any;
    isNoNeedUpdateList: any;
    items: Rule[];

    constructor(
        public popoverController: PopoverController,
        public libraryService: LibraryService,
        public storeService: StoreService,
        public modalController: ModalController,
        public routerOutlet: IonRouterOutlet,
        public socialShareService: SocialShareService,
        public tabsService: TabsService,
        public platform: Platform,
    ) {
        super(
            popoverController,
            libraryService,
            storeService,
            modalController,
            routerOutlet,
            socialShareService,
            tabsService,
            platform,
        );
    }

    ngOnInit() {
        this.isLoading = true;

        this.storeService.store$
            .pipe(
                filter((state: AppState) => state.ready),
                tap(() => {
                    this.isLoading = true;
                }),
                debounceTime(500),
                map((state: AppState) => {
                    const favorites = ld.cloneDeep(state.favorites || []);
                    return ld.filter(state.rules, (rule: Rule) => favorites.includes(rule.id));
                }),
                untilDestroyed(this),
            )
            .subscribe((rules: Rule[]) => {
                this.isLoading = false;
                this.items     = rules;
            });
    }

    ngOnDestroy(): void {
    }
}
