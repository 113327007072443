import { Component, OnInit, Input } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { Rule } from '@app/core/store/models/rule.model';

@Component({
    selector:    'app-rule-details',
    templateUrl: './rule-details.component.html',
    styleUrls:   ['./rule-details.component.scss'],
})
export class RuleDetailsComponent implements OnInit {
    @Input() rule: Rule;
    imgHeight: number;
    constructor(
        private modalCtrl: ModalController,
        private platform: Platform,
    ) { }

    ngOnInit() {
        this.platform.ready().then(() => {
            this.imgHeight = Math.floor((this.platform.width() - 32) * 0.7730);
            // console.log('this.imgHeight: ', this.imgHeight);
        });
    }

    dismissModal() {
        // using the injected ModalController this page
        // can "dismiss" itself and optionally pass back data
        this.modalCtrl.dismiss({
            dismissed: true,
        });
    }
}
