import { Injectable } from '@angular/core';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { Rule } from '@app/core/store/models/rule.model';
import { environment } from '@env/environment';

interface ShareItem {
    link: string;
    imgUrl: string;
    text: string;
}

@Injectable({
    providedIn: 'root',
})
export class SocialShareService {
    text: string = 'Flamenco'
    imgurl: string = 'https://cdn.pixabay.com/photo/2019/12/26/05/10/pink-4719682_960_720.jpg'
    link: string = 'https://link.medium.com/JA4amAHFJ5'

    constructor(
        private socialSharing: SocialSharing,
    ) { }

    shareGeneric(rule: Rule) {
        const item = this.prepareRuleForShare(rule);
        this.socialSharing.share(item.text, 'MEDIUM', null, item.link);
    }

    shareWhatsapp(rule: Rule) {
        const item = this.prepareRuleForShare(rule);
        this.socialSharing.shareViaWhatsApp(item.text, item.imgUrl, this.link);
    }

    shareFacebook(rule: Rule) {
        const item = this.prepareRuleForShare(rule);
        this.socialSharing.shareViaFacebookWithPasteMessageHint(item.text, item.imgUrl, item.text);
    }

    shareEmail(rule: Rule) {
        const item = this.prepareRuleForShare(rule);
        this.socialSharing.shareViaEmail('text', 'subject', ['email@address.com']);
    }

    shareTwitter(rule: Rule) {
        const item = this.prepareRuleForShare(rule);
        this.socialSharing.shareViaTwitter(item.text, item.imgUrl, this.link);
    }

    shareInstagram(rule: Rule) {
        const item = this.prepareRuleForShare(rule);
        this.socialSharing.shareViaInstagram(item.text, item.imgUrl);
    }

    private prepareRuleForShare(rule: Rule): ShareItem {
        return {
            link:   `${environment.serverUrl}/${rule.uri}`,
            imgUrl: rule.fullImgUrl,
            text:   rule.title,
        };
    }
}
