import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { FavoritesRoutingModule } from './favorites-routing.module';
import { FavoritesComponent } from './favorites.component';
import { QuoteService } from './quote.service';

@NgModule({
    imports: [
        CommonModule,
        // CoreModule,
        SharedModule,
        IonicModule,
        FavoritesRoutingModule,
    ],
    entryComponents: [FavoritesComponent],
    declarations:    [FavoritesComponent],
})
export class FavoritesModule {}
