import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { of } from 'rxjs';
import { Exercise } from '@app/core/store/models/exercise.model';
import { ld } from '@app/core';
import { Practice, Question } from './one-practice/practice.model';

@Injectable({
    providedIn: 'root',
})
export class TrainingService {
    constructor(private httpClient: HttpClient) {}

    public preparePractice(fromServer: Exercise): Practice {
        const questions = ld.map(JSON.parse(fromServer.tasks), (question: any) => ({
                id:          question.MIGX_id,
                explanation: question.explanation,
                title:       question.questions,
                variants:    question.answers.split('|').map((item: any, index: number) => ({ name: item, value: index })),
                correct:      +question.correct,
                userSelected: null,
            }));
        const practice  = {
            id:    fromServer.id,
            title: fromServer.title,
            questions,
        };
        return practice;
    }
}
