import { Component, OnInit, ViewChild } from '@angular/core';
import { IonTabs } from '@ionic/angular';
import { Router } from '@angular/router';
import { AppState, StoreService } from '@app/core/store/store.service';
import { untilDestroyed } from '@app/core';
import { Exercise } from '@app/core/store/models/exercise.model';
import { environment } from '@env/environment';
import { filter, map } from 'rxjs/operators';
import { TabsService } from './tabs.service';

@Component({
    selector:    'app-shell',
    templateUrl: './shell.component.html',
    styleUrls:   ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
    @ViewChild('ion-tabs') tabs: IonTabs;
    resetStackTabs = ['library'];
    totalFavorites: number;

    constructor(
        private router: Router,
        private storeService: StoreService,
        private tabsService: TabsService,
    ) {}

    handleTabClick = (event: MouseEvent) => {
        event.stopImmediatePropagation();
        this.router.navigate(['/tabs/training']);
    };

    libraryWasClicked() {
        this.tabsService.libraryTabWasSelected.next(true);
    }

    ngOnInit() {
        this.storeService.store$
            .pipe(
                filter((state: AppState) => state.ready),
                map((state: AppState) => state.favorites || []),
                untilDestroyed(this),
            )
            .subscribe((favorites: number[]) => {
                this.totalFavorites = favorites.length;
            });
    }

    ngOnDestroy(): void {
    }
}
