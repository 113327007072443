import { Observable, of, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AlertController } from '@ionic/angular';

export interface CanOnePracticeDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateOnePracticeGuard implements CanDeactivate<CanOnePracticeDeactivate> {
    constructor(
        private alertController: AlertController,
    ) {

    }

    canDeactivate(component: CanOnePracticeDeactivate,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot) {
        const { url } = state;
        return from(this.presentAlertConfirm());
    }

    async presentAlertConfirm():Promise<boolean> {
        // await alert.present();
        return new Promise((resolve: any, reject: any) => {
            this.alertController.create({
                header:  'Ви дійсно хочете завершити вправу?',
                // message: 'Результат проходження вправи буде втрачено',
                buttons: [
                    {
                        text:     'Відмінити',
                        role:     'cancel',
                        cssClass: 'secondary',
                        handler:  (blah) => {
                            resolve(false);
                        },
                    }, {
                        text:    'Так',
                        handler: () => {
                            resolve(true);
                        },
                    },
                ],
            }).then(alert => alert.present());
        });
    }
}
