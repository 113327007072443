import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { of } from 'rxjs';
import { StoreService } from '@app/core/store/store.service';
import { ld } from '@app/core';

@Injectable({
    providedIn: 'root',
})
export class LibraryService {
    constructor(
        private httpClient: HttpClient,
        private storeService: StoreService,
    ) {}

    public getExamples() {
        // return of(true);
        return this.httpClient.get('?route=examples&minId=560').pipe(
            map((items: any[]) => {
                const preparedItems = items.map((item) => {
                    item.fullImg = `https://ukr-mova.in.ua/${item.image}`;
                    return item;
                });

                return preparedItems;
            }),
        );
    }

    public addToFavorite(id: number) {
        return this.storeService.getFavoritesRules().pipe(
            switchMap((currentFavorites: number[]) => {
                const newList = ld.cloneDeep(currentFavorites || []);
                newList.push(id);
                return this.storeService.setStoreData('favorites', ld.uniq(newList));
            }),
        );
    }

    public removeFromFavorite(id: number) {
        return this.storeService.getFavoritesRules().pipe(
            switchMap((currentFavorites: number[]) => {
                const newList = ld.cloneDeep(currentFavorites || []);
                return this.storeService.setStoreData('favorites', ld.filter(newList, item => item !== id));
            }),
        );
    }
}
