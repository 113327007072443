import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings.component';

@NgModule({
    imports:         [CommonModule, IonicModule, SettingsRoutingModule],
    entryComponents: [SettingsComponent],
    declarations:    [SettingsComponent],
})
export class SettingsModule {}
