import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { Shell } from '@app/shell/shell.service';
import { FavoritesComponent } from './favorites.component';

const routes: Routes = [
    Shell.childRoutes([
        { path: '', redirectTo: '/favorites', pathMatch: 'full' },
        { path: 'favorites', component: FavoritesComponent, data: { title: extract('Favorites') } },
    ]),
];

@NgModule({
    imports:   [RouterModule.forChild(routes)],
    exports:   [RouterModule],
    providers: [],
})
export class FavoritesRoutingModule {}
