import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { merge } from 'rxjs';
import { filter, map, switchMap, tap, first } from 'rxjs/operators';
import { Platform, LoadingController } from '@ionic/angular';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { SplashScreen } from '@capacitor/splash-screen';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';

import { environment } from '@env/environment';
import { Logger, untilDestroyed } from '@app/core';
import { StoreService, AppState } from './core/store/store.service';
import { QueueService } from './core/queue.service';

import { Storage } from '@ionic/storage-angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

const log = new Logger('App');

declare let window: any; // <--- Declare it like this

@Component({
    selector:    'app-root',
    templateUrl: './app.component.html',
    styleUrls:   ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    loading: HTMLIonLoadingElement;
    constructor(
        // private router: Router,
        // private activatedRoute: ActivatedRoute,
        // private titleService: Title,
        private platform: Platform,
        private keyboard: Keyboard,
        // private statusBar: StatusBar,
        // private splashScreen: SplashScreen,

        private storeService: StoreService,
        public loadingController: LoadingController,
        private storage: Storage,
        public queueService: QueueService,
    ) {}

    async ngOnInit() {
        // Setup logger
        if (environment.production) {
            Logger.enableProductionMode();
        }

        await this.storage.defineDriver(CordovaSQLiteDriver);
        await this.storage.create();

        log.debug('init');

        // this.presentLoading();
        this.storeService
            .initStore()
            .pipe(
                filter((state: AppState) => !state.ready),
                first(),
            )
            .subscribe((state: AppState) => {
                if (window.cordova) {
                    this.queueService.initQueue(state[1]);
                }
            });

        // Cordova platform and plugins initialization
        await this.platform.ready();
        this.onCordovaReady();
    }

    ngOnDestroy() {
    }

    async presentLoading() {
        this.loading = await this.loadingController.create({
            message: 'Зачекайте, б ласка...',
        });
        await this.loading.present();

        const { role, data } = await this.loading.onDidDismiss();
        console.log('Loading dismissed!');
    }

    private async onCordovaReady() {
        log.debug('device ready');

        if (window.cordova) {
            log.debug('Cordova init');
            setTimeout(async () => {
                await SplashScreen.hide();
            }, 1000);

            this.keyboard.hideFormAccessoryBar(true);
            // this.statusBar.styleDefault();
            // this.statusBar.overlaysWebView(true);
            // this.splashScreen.hide();
        }
    }
}
