import * as _ from 'lodash';
import * as Moment from 'moment';

export * from './core.module';
export * from './authentication/authentication.service';
export * from './authentication/credentials.service';
export * from './authentication/authentication.guard';
export * from './http/api-prefix.interceptor';
export * from './http/error-handler.interceptor';
export * from './route-reusable-strategy';
export * from './logger.service';
export * from './until-destroyed';

Moment.locale('en-US');

export const ld = _;
export const moment = Moment;

export function extract(str) {
    return str;
}
