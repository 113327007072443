import { Component, OnInit, OnDestroy } from '@angular/core';

import { environment } from '@env/environment';
import { finalize, filter, map, tap, timeout, debounceTime } from 'rxjs/operators';
import { StoreService, AppState } from '@app/core/store/store.service';
import { PopoverController, ModalController, IonRouterOutlet, Platform } from '@ionic/angular';
import { ld, untilDestroyed } from '@app/core';
import { Rule } from '@app/core/store/models/rule.model';
// import { trigger, transition, animate, style } from '@angular/animations';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { SocialShareService } from '@app/shared/social-share.service';
import { LibraryService } from './library.service';
import { LibraryFilterComponent } from './library-filter/library-filter.component';
import { RuleDetailsComponent } from './details/rule-details/rule-details.component';
import { TabsService } from '../shell/tabs.service';
import { Capacitor } from '@capacitor/core';

@Component({
    selector:        'app-library',
    templateUrl:     './library.component.html',
    styleUrls:       ['./library.component.scss'],
    entryComponents: [LibraryFilterComponent],
    // animations:      [
    //     trigger('slideInOut', [
    //         transition(':enter', [
    //             style({ transform: 'translateX(-100%)' }),
    //             animate('200ms ease-in-out', style({ transform: 'translateX(0%)' })),
    //         ]),
    //         transition(':leave', [
    //             animate('200ms ease-in-out', style({ transform: 'translateX(-100%)' })),
    //         ]),
    //     ]),
    //     trigger('fadeIn', [
    //         transition('void => *', [
    //             style({ opacity: 0 }),
    //             animate(250, style({ opacity: 1 })),
    //         ]),
    //     ]),
    // ],
})
export class LibraryComponent implements OnInit, OnDestroy {
    version: string | null = environment.version;
    isLoading = true;

    items: any;
    totalSelectedCategories: number;
    start: any = 0;
    threshold = 150 ;
    showSearchBar = true;
    showSearchBarPrevious = 0;
    heightOfScreen: number;

    public imgHeight: number;

    public search$: BehaviorSubject<any> = new BehaviorSubject(null);
    public isNoNeedUpdateList = false;


    constructor(
        public popoverController: PopoverController,
        public libraryService: LibraryService,
        public storeService: StoreService,
        public modalController: ModalController,
        public routerOutlet: IonRouterOutlet,
        public socialShareService: SocialShareService,
        public tabsService: TabsService,
        public platform: Platform,
    ) {}

    ngOnInit() {

        this.isLoading = true;

        this.platform.ready().then(() => {
            this.imgHeight = Math.floor((this.platform.width() - 32) * 0.7730);
            // console.log('this.imgHeight: ', this.imgHeight);
            // this.heightOfScreen = this.platform.height();
        });

        this.tabsService.libraryTabWasSelected
            .pipe(
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.search$.next('');
            });

        combineLatest([this.storeService.store$, this.search$])
            .pipe(
                filter(([state, search]: [AppState, string]) => {
                    if (this.isNoNeedUpdateList) {
                        this.isNoNeedUpdateList = false;
                        return false;
                    }
                    return state.ready;
                }),
                tap(([state, search]: [AppState, string]) => {
                    this.isLoading = true;
                }),
                debounceTime(500),
                map(([state, search]: [AppState, string]) => {
                    // console.log(ld.find(state.rules, (rule: Rule) => rule.localImage));
                    if (search) {
                        const found = this.filterByQuery(state.rules, search);
                        // console.log('found: ', found);
                        return found;
                    }
                    const selectedCategories       = ld.filter(state.categories, 'selected').map(item => item.id);
                    this.totalSelectedCategories   = selectedCategories.length;
                    const favorites                = ld.cloneDeep(state.favorites || []);
                    const onlyInSelectedCategories = ld.filter(state.rules, (rule: Rule) => {
                        return selectedCategories.includes(rule.category) && !favorites.includes(rule.category);
                    });
                    return ld.map(onlyInSelectedCategories, (rule: Rule) => {
                        rule.inFavorite = favorites.includes(rule.id);
                        return rule;
                    });
                }),
                map((rules: Rule[])=>{
                    const preparedItems = rules.map((item: Rule) => {
                        item.fullImgUrl   = item.localImage
                        ? Capacitor.convertFileSrc(item.localImage) : `${environment.serverUrl}/${item.image}`;
                        return item;
                    });
                    return ld.shuffle(preparedItems);
                }),
                untilDestroyed(this),
            )
            .subscribe((rules: Rule[]) => {
                // console.log('rules: ', rules);
                this.isLoading = false;
                // console.log('this.isLoading: ', this.isLoading);
                this.items     = rules;
            });
    }

    ngOnDestroy() {
    }

    public async presentPopover(ev: any) {
        const popover = await this.popoverController.create({
            animated:       true,
            component:      LibraryFilterComponent,
            event:          ev,
            translucent:    true,
            componentProps: {
                currentSelected: this.totalSelectedCategories,
            },
        });
        return await popover.present();
    }

    onScroll(e: any) {
        // if (e.detail.scrollTop - this.start > this.threshold) {
        //     this.showSearchBar = false;
        // } else {
        //     this.showSearchBar = true;
        // }
        // // console.log('(e.detail.scrollTop: ', e.detail);
        // // console.log('this.heightOfScreen: ', this.heightOfScreen);
        // if ((this.showSearchBarPrevious >= e.detail.scrollTop - this.start)) {
        //     this.showSearchBar = true;
        // }
        // this.showSearchBarPrevious = e.detail.scrollTop - this.start;
    }

    public searchFiledWasChanged(event: any) {
        const searchTerm = event.srcElement.value;
        // if (!searchTerm || searchTerm.length < 2) {
        //     return;
        // }
        this.search$.next(searchTerm);
    }

    public filterByQuery(list: any, searchQuery: string) {
        return ld.filter(list, (item) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const property in item) {
                if (item[property] === null || item[property] === undefined) {
                    // eslint-disable-next-line no-continue
                    continue;
                }
                if (
                    item[property]
                        .toString()
                        .toLowerCase()
                        .includes(searchQuery.toLowerCase())
                ) {
                    return true;
                }
            }
            return false;
        });
    }

    public share(rule: Rule) {
        this.socialShareService.shareGeneric(rule);
    }

    public changeFavorite(rule: Rule) {
        this.isNoNeedUpdateList = true;
        const obs$              = rule.inFavorite ?
        this.libraryService.removeFromFavorite(rule.id) : this.libraryService.addToFavorite(rule.id);
        obs$.subscribe(() => {
            rule.inFavorite = !rule.inFavorite;
        });
    }

    public doRefresh(event: any) {
        this.search$.next('');
        event.target.complete();
    }

    async presentModal(rule: Rule) {
        // this.showSearchBar = false;
        await new Promise((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, 100);
        });
        const modal = await this.modalController.create({
            component:         RuleDetailsComponent,
            swipeToClose:      true,
            // backdropDismiss:   false,
            showBackdrop:      false,
            // animated:      false,
            presentingElement: this.routerOutlet.nativeEl,
            componentProps:    {
                rule,
            },
        });
        return await modal.present().then(async () => {
            // await new Promise((resolve) => {
            //     setTimeout(() => {
            //         resolve(true);
            //     }, 100);
            // });
            // console.log('showSearchBar: ', 'showSearchBar');
            // this.showSearchBar = true;
        });
    }

    public imgWasLoaded(rule: Rule) {
        rule.loaded = true;
        // console.log('$event: ', $event);
    }
}
