import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule } from '@ionic/angular';

import { SharedModule } from '@app/shared';
import { TrainingRoutingModule } from './training-routing.module';
import { TrainingComponent } from './training.component';
import { OnePracticeComponent } from './one-practice/one-practice.component';
import { DetailsComponent } from './one-practice/details/details.component';

@NgModule({
    imports:         [CommonModule, SharedModule, IonicModule, TrainingRoutingModule],
    entryComponents: [TrainingComponent],
    declarations:    [TrainingComponent, OnePracticeComponent, DetailsComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TrainingModule {}
